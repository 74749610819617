import { SignupInputFieldName } from "@finbackoffice/enums";
import { SignupConfigType } from "@finbackoffice/fe-core";
import { ConfigContext } from "@finbackoffice/site-core";
import { useContext, useMemo } from "react";

export const useSignupConfig = (fields?: SignupInputFieldName[]) => {
    const { signupConfig, siteRegistrationConfig } = useContext(ConfigContext);

    const signupConfigObj = useMemo(() => {
        const result: Partial<Record<SignupInputFieldName, SignupConfigType>> = {};

        signupConfig?.forEach((item) => {
            if (item.name === SignupInputFieldName.Password) {
                item = {
                    ...item,
                    has_digit: siteRegistrationConfig?.password_has_digit || false,
                    has_letter: siteRegistrationConfig?.password_has_letter || false,
                };
            }
            if (item.enable && (fields ? fields.includes(item.name) : true)) {
                result[item.name] = item;
            }
        });

        return result;
    }, [
        fields,
        signupConfig,
        siteRegistrationConfig?.password_has_digit,
        siteRegistrationConfig?.password_has_letter,
    ]);

    const signupConfigMemo = useMemo(
        () => signupConfig?.filter((field) => field.enable),
        [signupConfig],
    );

    return { signupConfigObj, signupConfig: signupConfigMemo };
};
