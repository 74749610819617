export const ModalTypes = {
    SIGNUP: "signup",
    VERIFICATION: "verification",
    VERIFICATION_ID: "verification_id",
    LOGIN: "login",
    ACCOUNT: "account",
    WALLET: "wallet",
    WALLET_VERIFY_TRANSACTION: "walletVerifyTransaction",
    SITE_NOTIFICATIONS: "siteNotifications",
    BANNERS: "banners",
    RESPONSIBLE_GAMING_CONFIRMATION: "responsibleGamingConfirmation",
    INACTIVITY: "inactivity",
};
