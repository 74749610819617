import { FC, PropsWithChildren, ReactNode, useContext, useEffect, useMemo } from "react";
import type { NextComponentType, Page } from "next";
import type { AppContext, AppInitialProps, AppLayoutProps, AppProps } from "next/app";
import {
    BannersProvider,
    BrowserStorageProvider,
    ClientProvider,
    PopupBannersProvider,
    SiteNotificationsProvider,
    I18nextProvider,
    CasinoFavoritesProvider,
    ChatProvider,
    LocaleProvider,
    ConfigProvider,
    UserAccountProvider,
    AuthProvider,
    AuthContext,
    ExchangeRatesProvider,
    ModalsProvider,
    BetSlipProvider,
    RuntimeConfigProvider,
    useRuntimeConfig,
    useSiteConfig,
    CrmProvider,
    ChatServiceProvider,
} from "@finbackoffice/site-core";
import { AxiosLikeErrorMiddleware } from "@finbackoffice/http-client";
import { ClientBFFClient } from "@finbackoffice/clientbff-client";
import { captureException } from "@sentry/nextjs";
import { useRouter } from "next/router";
import { PopupTags, SiteContentScopes } from "@finbackoffice/enums";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { AccountConfigType, SignupConfigType } from "@finbackoffice/fe-core";
import { useSearchParams } from "next/navigation";
import { InitialDataProvider } from "contexts";
import { WebsocketProvider } from "contexts";
import { MarketUpdatesProvider } from "contexts";
import Header from "components/header/Header";
import { DropArea, DraggableProvider } from "contexts";
import { NotificationProvider } from "contexts";
import CookiesConsent from "components/cookie-consent/CookiesConsent";
import { generateUUID } from "utils/helpers";
import "../styles/normalize.sass";
import "../styles/base.sass";
import "../styles/common-variables.sass";
import { CommonSsrProps } from "services/ssr";
import { AffiseInitScript } from "components/base/external-scripts/AffiseTracker";
import ChatScript from "components/base/external-scripts/ChatScript";
import { initializeGoogleTagManager } from "../../public/assets/js/gtm";

type IProvidersProps = PropsWithChildren & {
    pageProps: CommonSsrProps;
};

const Providers: FC<IProvidersProps> = ({ children, pageProps }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const accountFields = useSiteConfig<AccountConfigType[]>("accountFields");
    const signupFields = useSiteConfig<SignupConfigType[]>("signupFields");
    const router = useRouter();
    const { setToken } = useContext(AuthContext);

    const client = useMemo(() => {
        const origin =
            typeof window !== "undefined" && window.location.origin ? window.location.origin : "";

        const client = new ClientBFFClient(`${origin}/api/proxy/`, `sportsbook-desktop`, [
            new AxiosLikeErrorMiddleware({
                handleNotOkStatusCode(error, response) {
                    if (response.statusCode === 401) {
                        setToken("");
                    } else if (response.statusCode >= 500) {
                        const traceparent = response?.headers?.traceparent;
                        const traceId = traceparent ? traceparent?.split("-")[1] : "unknown";
                        captureException(error, {
                            tags: {
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                "trace-id": traceId,
                            },
                        });
                    }
                },
            }),
        ]);

        return client;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!COMMON_SITE_CONFIGS.google.tagManagerId) {
            initializeGoogleTagManager(COMMON_SITE_CONFIGS.google.tagManagerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LocaleProvider locale={pageProps.locale}>
            <I18nextProvider translations={pageProps.translations}>
                <BrowserStorageProvider>
                    <ClientProvider client={client}>
                        <UserAccountProvider
                            initialProfile={pageProps.profile}
                            uuid={generateUUID()}
                            initialTheme={
                                [SkinVersions.Camisa].includes(
                                    COMMON_SITE_CONFIGS.skinVersion as SkinVersions,
                                )
                                    ? "light"
                                    : "dark"
                            }>
                            <ConfigProvider
                                defaultConfig={pageProps.configs}
                                accountFields={accountFields}
                                signupFields={signupFields}
                                defaultCountry={COMMON_SITE_CONFIGS.country.default}
                                walletConfigs={COMMON_SITE_CONFIGS.wallet}>
                                <ExchangeRatesProvider
                                    fxCurrency={COMMON_SITE_CONFIGS.wallet.fxCurrency}>
                                    <NotificationProvider>
                                        <InitialDataProvider>
                                            <WebsocketProvider>
                                                <ChatProvider>
                                                    <BetSlipProvider>
                                                        <MarketUpdatesProvider>
                                                            <CasinoFavoritesProvider
                                                                favoriteGames={
                                                                    pageProps.casinoFavoriteGames ||
                                                                    null
                                                                }>
                                                                <SiteNotificationsProvider
                                                                    ignore={
                                                                        router.query.isTest ===
                                                                        "true"
                                                                    }
                                                                    appName={
                                                                        COMMON_SITE_CONFIGS.appName
                                                                    }>
                                                                    <PopupBannersProvider
                                                                        popupScope={
                                                                            pageProps.popupScope
                                                                        }
                                                                        device={PopupTags.Desktop}
                                                                        ignore={
                                                                            router.query.isTest ===
                                                                            "true"
                                                                        }
                                                                        appName={
                                                                            COMMON_SITE_CONFIGS.appName
                                                                        }>
                                                                        <BannersProvider
                                                                            device={
                                                                                SiteContentScopes.Desktop
                                                                            }
                                                                            banners={
                                                                                pageProps.banners
                                                                            }>
                                                                            <ModalsProvider>
                                                                                <DraggableProvider>
                                                                                    <CrmProvider>
                                                                                        <ChatServiceProvider
                                                                                            script={
                                                                                                <ChatScript />
                                                                                            }>
                                                                                            {
                                                                                                children
                                                                                            }
                                                                                        </ChatServiceProvider>
                                                                                    </CrmProvider>
                                                                                </DraggableProvider>
                                                                            </ModalsProvider>
                                                                        </BannersProvider>
                                                                    </PopupBannersProvider>
                                                                </SiteNotificationsProvider>
                                                            </CasinoFavoritesProvider>
                                                        </MarketUpdatesProvider>
                                                    </BetSlipProvider>
                                                </ChatProvider>
                                            </WebsocketProvider>
                                        </InitialDataProvider>
                                    </NotificationProvider>
                                </ExchangeRatesProvider>
                            </ConfigProvider>
                        </UserAccountProvider>
                    </ClientProvider>
                </BrowserStorageProvider>
            </I18nextProvider>
        </LocaleProvider>
    );
};

type Props = AppProps & {
    Component: Page;
};

const MyApp: NextComponentType<AppContext, AppInitialProps, AppLayoutProps<object>> = ({
    Component,
    pageProps,
}: Props) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const getLayout = Component.getLayout || ((page: ReactNode) => page);
    const params = useSearchParams();
    const skinVersion = pageProps.runtimeConfig.COMMON_SITE_CONFIGS.skinVersion;

    return (
        <>
            <Header />
            {getLayout(<Component {...pageProps} />)}
            {COMMON_SITE_CONFIGS.cookiesConsentPopup && <CookiesConsent />}
            {skinVersion === SkinVersions.Navy && params.get("clickid") && <AffiseInitScript />}
            <DropArea />
        </>
    );
};

export default ({ Component, ...rest }: AppProps) => (
    <AuthProvider initialJwt={rest.pageProps.jwt}>
        <RuntimeConfigProvider config={rest.pageProps.runtimeConfig}>
            <Providers pageProps={rest.pageProps}>
                <MyApp {...rest} Component={Component} />
            </Providers>
        </RuntimeConfigProvider>
    </AuthProvider>
);
